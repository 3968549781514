<!--  -->
<template>
  <div class="classroom">
    <h2 class="classroom_title">欢迎光临火花{{ cid }}</h2>
    <h3 class="classroom_sub_title">
      用技术开启职业之路，让河南学子创造中国互联网未来！
    </h3>
    <div id="seat">
      <div class="info">
        <ul>
          <li>班级：火花{{ cid }}</li>
        <!-- <li>专业：前端</li>
              <li>当前报名：25人</li> -->
          <li>班主任：曹文青</li>
          <li>人员总数：45人</li>
          <li>开班时间：2023-04-11</li>
        </ul>
      </div>
      <div style="clear: both"></div>
      <!--可选座位-->
      <div class="seatsRow">
        <h2 class="title">教室布局预览</h2>
        <ul class="seat" v-for="(list, index) in userLists" :key="index"
          :style="{ background: index % 2 != 0 ? '#efefef' : '' }">
          <li v-for="(item, index2) in list.children" @click="fn(index, index2, item.is_status)" :key="index2">
            <div style="
                    font-size: 14px;
                    height: 30px;
                    background: #efefef;
                    line-height: 30px;
                    color: #999;
                  " v-if="item.is_status != '2'">
              {{ 5 - index }}排{{ index2 + 1 }}座
            </div>
            <div class="td">
              <span v-if="item.is_status == '0'" @click.stop="
                selectFn(
                  list.id,
                  item.id,
                  item.is_status,
                  item.is_select,
                  index,
                  index2
                )
              ">
                <span v-if="item.is_select" style="background: #fff; font-weight: bold">空</span>
                <span v-else style="background: green; color: #fff">当前选择</span>
              </span>
              <span v-else-if="item.is_status == '1'" style="color: #fff; background: red; font-weight: bold">已占座</span>
              <span v-else :style="{
                'font-size': '14px',
                background: '#fff',
                height: item.is_status != '2' ? '30px' : '60px',
                'line-height': item.is_status != '2' ? '30px' : '60px',
              }">过道</span>
            </div>
          </li>
        </ul>
        <h2 class="title">讲师讲台</h2>
        <h2 class="title" v-if="seat">
          {{ seat }}
          <button class="submit_btn" @click="submitFn">确定</button>
          <button class="reset_btn" @click="resetFn">取消</button>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { ClassRoomApi, ClassRoomAddApi, SendMsg } from "@/api/home.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  //ComponentName和文件名保持一致
  name: "ComponentName",
  data() {
    //这里存放数据
    return {
      userLists: [],
      seat: "",
      id: 0,
      is_status: "0",
      cid: 0,
      seatNow: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    // seat(value) {
    //   // console.log(11111111111, value);
    // },
  },
  //方法集合
  methods: {
    fn(i, i2, is_status) {
      if (i2 == 4) {
        this.$message({
          message: "过道不能选",
          type: "warning",
        });
        return;
      }
      console.log(i, i2, is_status);
      if (is_status == "1") {
        this.$message({
          message: "该座位已经被占用",
          type: "warning",
        });
        return;
      }
      // console.log(`第${i + 1}排，第${i2 + 1}座`);
      this.seat = `当前选择：${5 - i}排${i2 + 1}座`;
      this.seatNow = `火花333班级: ${5 - i}排${i2 + 1}座`;
    },
    resetFn() {
      this.userLists.find((item) => {
        item.children.find((value) => {
          value.is_select = 1;
        });
      });
      this.seat = "";
    },
    async submitFn() {
      let result = await ClassRoomAddApi({
        id: this.id,
        is_status: this.is_status,
      });
      // console.log(result);
      if (result.data.status == 200) {
        let msgResult = await SendMsg({
          seat: this.seatNow,
        });
        // console.log(msgResult);
        if (msgResult.data.status == 200) {
          this.$message({
            message: result.data.msg,
            type: "success",
          });
          this.$router.push("/home");
        } else {
          this.$message({
            message: result.data.msg,
            type: "warning",
          });
        }
        this.getClassRoom();
        this.seat = "";
      } else {
        this.$message({
          message: result.data.msg,
          type: "warning",
        });
      }
    },
    selectFn(pid, id, is_status, item_is_select, index, index2) {
      // console.log(index, index2);
      this.fn(index, index2, is_status);
      // console.log(pid, id, is_status);
      this.userLists.find((item) => {
        item.children.find((value) => {
          if (value.id == id) {
            value.is_select = !value.is_select;
          } else {
            value.is_select = 1;
          }
        });
      });
      if (!item_is_select) {
        // console.log(12123132131313132);
        this.seat = "";
      }
      if (is_status == "0") {
        this.is_status = "1";
        this.id = id;
      }
    },
    async getClassRoom() {
      let result = await ClassRoomApi();
      // console.log(result.data.data);
      if (result.status == 200) {
        this.userLists = result.data.data;
      }
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getClassRoom();
    // console.log(this.$route.params.cid);
    this.cid = this.$route.params.cid;
  },
};
</script>
<style lang="less">
.classroom {
  .classroom_title {
    text-align: center;
    margin-top: 20px;
  }

  .classroom_sub_title {
    text-align: center;
  }

  #seat {
    margin: 30px 0px 0px 60px;
    width: 1000px;
    height: 600px;
    margin: 50px auto 0;

    .info {
      height: 40px;
      line-height: 40px;

      ul {
        li {
          float: left;
          border: 1px solid #ccc;
          padding: 0 20px;
          border-right: none;
        }

        li:last-child {
          border-right: 1px solid #ccc;
        }
      }
    }

    .seatsRow {
      .title {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        height: 60px;
        line-height: 60px;
        background: #efefef;

        button {
          width: 80px;
          height: 40px;
          color: #fff;
          text-align: center;
          line-height: 40px;
          margin-left: 10px;
          cursor: pointer;
          font-size: 16px;
          font-weight: bold;
        }

        button:hover {
          opacity: 0.8;
        }

        .submit_btn {
          background: red;
        }

        .reset_btn {
          background: #333;
        }
      }

      .seat {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 1000px;
        height: 60px;

        li {
          font-size: 20px;
          width: 100px;
          text-align: center;
          line-height: 60px;
          border: 1px solid #ccc;
          cursor: pointer;
          box-sizing: border-box;

          span {
            display: block;
            width: 100px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
</style>