var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"classroom"},[_c('h2',{staticClass:"classroom_title"},[_vm._v("欢迎光临火花"+_vm._s(_vm.cid))]),_c('h3',{staticClass:"classroom_sub_title"},[_vm._v(" 用技术开启职业之路，让河南学子创造中国互联网未来！ ")]),_c('div',{attrs:{"id":"seat"}},[_c('div',{staticClass:"info"},[_c('ul',[_c('li',[_vm._v("班级：火花"+_vm._s(_vm.cid))]),_c('li',[_vm._v("班主任：曹文青")]),_c('li',[_vm._v("人员总数：45人")]),_c('li',[_vm._v("开班时间：2023-04-11")])])]),_c('div',{staticStyle:{"clear":"both"}}),_c('div',{staticClass:"seatsRow"},[_c('h2',{staticClass:"title"},[_vm._v("教室布局预览")]),_vm._l((_vm.userLists),function(list,index){return _c('ul',{key:index,staticClass:"seat",style:({ background: index % 2 != 0 ? '#efefef' : '' })},_vm._l((list.children),function(item,index2){return _c('li',{key:index2,on:{"click":function($event){return _vm.fn(index, index2, item.is_status)}}},[(item.is_status != '2')?_c('div',{staticStyle:{"font-size":"14px","height":"30px","background":"#efefef","line-height":"30px","color":"#999"}},[_vm._v(" "+_vm._s(5 - index)+"排"+_vm._s(index2 + 1)+"座 ")]):_vm._e(),_c('div',{staticClass:"td"},[(item.is_status == '0')?_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.selectFn(
                list.id,
                item.id,
                item.is_status,
                item.is_select,
                index,
                index2
              )}}},[(item.is_select)?_c('span',{staticStyle:{"background":"#fff","font-weight":"bold"}},[_vm._v("空")]):_c('span',{staticStyle:{"background":"green","color":"#fff"}},[_vm._v("当前选择")])]):(item.is_status == '1')?_c('span',{staticStyle:{"color":"#fff","background":"red","font-weight":"bold"}},[_vm._v("已占座")]):_c('span',{style:({
              'font-size': '14px',
              background: '#fff',
              height: item.is_status != '2' ? '30px' : '60px',
              'line-height': item.is_status != '2' ? '30px' : '60px',
            })},[_vm._v("过道")])])])}),0)}),_c('h2',{staticClass:"title"},[_vm._v("讲师讲台")]),(_vm.seat)?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.seat)+" "),_c('button',{staticClass:"submit_btn",on:{"click":_vm.submitFn}},[_vm._v("确定")]),_c('button',{staticClass:"reset_btn",on:{"click":_vm.resetFn}},[_vm._v("取消")])]):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }